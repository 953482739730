import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LegalQuotesIntro'

// markup
const LatQuotes_q = () => {
  return (
   <Layout >
      <Head title='Latin Legal Maxims, Writs and Expressions - Q'/>
      <h1>Legal Latin maxims and expressions</h1>
     <h2>Q</h2>
     <LatQuotesIntro />
    
     <p><b>Quaeitur</b> - The question is raised.</p>
<p><b>Quantum</b> - How much, an amount.</p>
<p><b>Qui facit per alium, facit per se</b> - He who acts through another acts himself.</p>
<p><b>Qui haeret in litera, haeret in cortice</b> - He who stices to the letter, sticks to the bark.</p>
<p><b>Qui in utero est, pro jam nato habetur, quoties de ejus commodo quaeritur</b> - He who is in the womb is considered as already born as far as his benefit is considered.</p>
<p><b>Qui non habet potestatem alienandi, habet necessitatem retinendi</b> - He who has not the power of alienating is under the necessity of retaining.</p>
<p><b>Qui non habet, ille non dat</b> - He who has not, does not give.</p>
<p><b>Qui non improbat, approbat</b> - He who does not disapprove, approves.</p>
<p><b>Qui non obstat quod obstare potest facere videtur</b> - He who does not prevent what he is able to prevent, is considered as committing the thing.</p>
<p><b>Qui non prohibet quod prohibere potest assentire videtur</b> - He who does not prohibit when he is able to prohibit, is in fault.</p>
<p><b>Qui peccat ebrius, luat sobrius</b> - He who does wrong when drunk must be punished when sober.</p>
<p><b>Qui potest et debet vetare et non vetat jubet</b> - He who is able and ought to forbit and does not, commands.</p>
<p><b>Qui prior est tempore potior est jure</b> - He who is prior in time is stronger in right.</p>
<p><b>Qui sentit commodum, debet et sentire onus</b> - He who derives a benefit ought also to bear a burden.</p>
<p><b>Qui tacet consentire videtur</b> - He who is silent appears to consent.</p>
<p><b>Quid pro quo</b> - Consideration. something for something.</p>
<p><b>Quidcquid plantatur solo, solo cedit</b> - Whatever is planted in or affixed to the soil, belongs to the soil.</p>
<p><b>Quod ab initio non valet, in tractu temporis non convalescit</b> - What is not valid in the beginning does not become valid by time.</p>
<p><b>Quod constat curiae opere testium non indiget</b> - What appears to the Court needs not the help of witnesses.</p>
<p><b>Quod necessarie intelligitur, id non deest</b> - What is necessarily understood is not wanting.</p>
<p><b>Quod necessitas cogit, defendit</b> - What necessity forces it justifies.</p>
<p><b>Quod non apparet, non est</b> - What does not appear, is not.</p>
<p><b>Quod non habet principium non habet finem</b> -  What has no beginning has no end.</p>
<p><b>Quod per me non possum, nec per alium</b> - What I cannot do through myself, I cannot do through another.</p>
<p><b>Quod prius est verius est; et quod prius est tempore potius est jure</b> - What is first is more true; and what is prior in time is stronger in law.</p>
<p><b>Quod vanum et inutile est, lex non requirit</b> - The law does not require what is vain and useless.</p>
<p><b>Quoties in verbis nulla est ambiguitas, ibi nulla expositio contra verba expressa fienda est</b> - When there is no ambiguity in words, then no exposition contrary to the expressed words is to be made.</p>

   </Layout>
  )
}

export default LatQuotes_q
